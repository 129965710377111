import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Table, Select, Button, Form } from "antd";

import "antd/dist/antd.min.css";

import { fetchCemeteryInformation } from "../../../api/cemeteryApi";
import { years, cemeteries, searchColumns } from "../data";
import "./SearchPage.css";

const formatDropdownValue = (data: any) => {
  return data.map((item: any) => {
    return {
      label: item,
      value: item,
    };
  });
};

const SearchPage = () => {
  const navigate = useNavigate();

  const [response, setResponse] = useState<any>([]);
  const [visible, setVisible] = useState<boolean>(false);

  const [paginationPage, setPaginationPage] = useState(1);

  useEffect(() => {
    let data = sessionStorage.getItem("cemeteryResults");
    if (data) {
      setVisible(true);
      setResponse(JSON.parse(data));
    }
  }, []);

  const filterColumns = (columns: { key: string }[]) => {
    if (window.innerWidth > 481) {
      columns = columns.filter((column) => column.key !== "Mobile");
    } else {
      columns = columns.filter((column) => column.key === "Mobile");
    }
    return columns;
  };

  function getUniqueListBy(arr: any, key: any) {
    return [...new Map(arr.map((item: any) => [item[key], item])).values()];
  }

  const onFinish = (values: any) => {
    const arr: object[] = [];
    if (
      Object.entries(values)
        .map((item) => item[1])
        .every((element) => element === "" || element === "All")
    ) {
      setResponse([]);
      sessionStorage.removeItem("cemeterySearch");
      sessionStorage.removeItem("cemeteryResults");
      return;
    }
    Object.keys(values).forEach((key) => {
      if (values[key] === "All" || values[key] === undefined) {
        values[key] = "";
      }
      if (key === "date") {
        values[key] = values[key].toString();
      }
      values[key] = values[key].replace(/\s+/g, " ").trim();
    });
    fetchCemeteryInformation(values).then((response) => {
      response.forEach((item: any) => {
        arr.push(item.attributes);
      });
      let arr1 = getUniqueListBy(arr, "ID");

      arr1 = arr1.sort((a:any, b:any) => {
        const result = a.Surname.localeCompare(b.Surname);
        return result !== 0 ? result : a.FirstNames.localeCompare(b.FirstNames);
      })
      sessionStorage.setItem("cemeteryResults", JSON.stringify(arr1));
      setResponse(arr1);
      setVisible(true);
      setPaginationPage(1);
    });
    sessionStorage.setItem("cemeterySearch", JSON.stringify(values));
  };


  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Form
        name="basic"
        initialValues={{
          remember: true,
          firstName: sessionStorage.getItem("cemeterySearch")
            ? JSON.parse(sessionStorage.getItem("cemeterySearch")!).firstName.replace(/%27%27/g, "'")
            : "",
          lastName: sessionStorage.getItem("cemeterySearch")
            ? JSON.parse(sessionStorage.getItem("cemeterySearch")!).lastName.replace(/%27%27/g, "'")
            : "",
          date: "All",
          cemetery: "All",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="inline"
        className="form"
      >
        <div className={"searchDiv"}>
          <div>First Name</div>
          <Form.Item name="firstName"
          rules={[{ whitespace:true, message:"Name Invalid" }]}>
            <Input allowClear size="large" />
          </Form.Item>
        </div>
        <div className={"searchDiv"}>
          <div>Last Name</div>
          <Form.Item name="lastName"
          rules={[{ whitespace:true, message:"Name Invalid" }]}>
            <Input allowClear size="large" />
          </Form.Item>
        </div>

        <div className={"searchDiv"}>
          <div>Year of Death</div>
          <Form.Item className="searchInput" name="date">
            <Select
              showSearch
              size="large"
              options={formatDropdownValue(years())}
            />
          </Form.Item>
        </div>
        <div className={"searchDiv"}>
          <div>Cemetery</div>
          <Form.Item className="searchInput" name="cemetery">
            <Select size="large" options={formatDropdownValue(cemeteries)} />
          </Form.Item>
        </div>
        <Form.Item>
          <Button className="submitButton" size="large" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Form>
      {visible ? (
        <Table
          onRow={(record) => {
            return {
              onClick: () => {
                navigate("/cemetery/details", {
                  state: { record: record },
                });
              },
            };
          }}
          className="table"
          rowKey="ID"
          columns={filterColumns(searchColumns)}
          dataSource={response}
          locale={{ emptyText: "No Results Found" }}
          rowClassName="rowClass"
          pagination={{
            current: paginationPage,
            onChange: (pageNum) => setPaginationPage(pageNum),
          }}
        />
      ) : null}
    </>
  );
};

export default SearchPage;
