export const CurrencyFormatter = () =>
  new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" });

export const CurrencyFormat = (
  value: number,
  options?: {
    formatter?: Intl.NumberFormat;
    withSymbol?: boolean;
    noDecimal?: boolean;
  }
) => {
  const formatter =
    options && options.formatter ? options.formatter : CurrencyFormatter();

  let formatted =
    options && options.withSymbol
      ? formatter.format(value)
      : formatter.format(value).replace("$", "");

  if (options && options.noDecimal && value % 1 === 0) {
    formatted = formatted.replace(/\.00$/, ""); // Assumes .00 is the decimal part for whole numbers
  }

  return formatted;
};

export const CurrencyString = (
  value: number,
  options?: { formatter?: Intl.NumberFormat }
) => {
  const formatter =
    options && options.formatter ? options.formatter : CurrencyFormatter();

  const formatted = formatter.format(value * 0.01).replace(/\$|,/g, "");

  return formatted;
};
