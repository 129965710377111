import React from "react";
import { Typography } from "antd";
const { Text } = Typography;

export const years = () => {
  const currentYear = new Date().getFullYear(),
    years = [];
  let startYear = 1871;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  years.unshift("All");
  return years;
};

export const cemeteries = [
  "All",
  "Ashhurst Cemetery",
  "Bunnythorpe Cemetery",
  "Kelvin Grove Cemetery",
  "Terrace End Cemetery",
];

export function formatDate(input: string) {
  if (input.match(/^[^a-zA-Z]+$/)) {
    var datePart = input.match(/\d+/g),
      year = datePart![0].substring(0, 4), // get only two digits
      month = datePart![1],
      day = datePart![2];

    return day + "/" + month + "/" + year;
  } else return "UNKNOWN";
}

export const DetailsValues: {
  key: string;
  value: string;
  format?: "date";
}[] = [
  { key: "Surname", value: "Surname" },
  { key: "FirstNames", value: "Firstname" },
  { key: "Gender", value: "Gender" },
  { key: "Occupation", value: "Occupation" },
  { key: "Address", value: "Address" },
  { key: "Denomination", value: "Denomination" },
  { key: "Age", value: "Age At Death" },
  { key: "DateOfDeath", value: "Date of Death", format: "date" },
  { key: "PlaceOfDeath", value: "Place of Death" },
  { key: "BurialDate", value: "Date of Interment" },
  { key: "WarrantType", value: "Warrant Type" },
  { key: "Cemetery", value: "Cemetery" },
  { key: "Section", value: "Area in Cemetery" },
  { key: "Block", value: "Cemetery Block" },
  { key: "PlotLocation", value: "Plot Location" },
  { key: "PlotDepth", value: "Plot Depth" },
  { key: "FuneralDirector", value: "Funeral Director" },
  { key: "BookOfMemories", value: "Book of Memories" },
];

export const searchColumns: any = [
  {
    key: "Mobile",
    render: (text: string, record: any) => (
      <>
        <span className={"link-first-name"}>
          {`${record.FirstNames} ${record.Surname}`}
        </span>
        <div>
          <span style={{ fontWeight: "bold" }}>YEAR OF DEATH: </span>
          <span>{formatDate(record.DateOfDeath)}</span>
        </div>
        <span>{record.Cemetery}</span>
      </>
    ),
  },
  {
    title: "First Name",
    dataIndex: "FirstNames",
    key: "FirstNames",
    render: (text: string) => <span className={"link-first-name"}>{text}</span>,
    sorter: {
      compare: (a: any, b: any) => a.FirstNames.localeCompare(b.FirstNames),
    },
    width: "25%",
  },
  {
    title: "Last Name",
    dataIndex: "Surname",
    key: "Surname",
    sorter: {
      compare: (a: any, b: any) => a.Surname.localeCompare(b.Surname),
    },
    width: "25%",
  },
  {
    title: "Year Of Death",
    dataIndex: "DateOfDeath",
    key: "DateOfDeath",
    render: (text: string) => <Text>{formatDate(text)}</Text>,
    width: "25%",
    sorter: {
      compare: (a: any, b: any) => a.DateOfDeath.localeCompare(b.DateOfDeath),
    },
  },
  {
    title: "Cemetery",
    dataIndex: "Cemetery",
    key: "Cemetery",
    width: "25%",
    sorter: {
      compare: (a: any, b: any) => a.Cemetery.localeCompare(b.Cemetery),
    },
  },
];
