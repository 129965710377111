import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Table, Image, Button, Spin, Collapse, Layout } from "antd";
import "antd/dist/antd.min.css";
import { ColumnsType } from "antd/es/table";
import { LeftOutlined, LoadingOutlined } from "@ant-design/icons";

import {
  fetchConfig,
  fetchProposedRates,
  fetchRatesBreakdown,
} from "../../../api/propertyApi";
import {
  PropertyValues,
  ratesValues,
  ratesColumns,
  Rates,
  proposedRatesColumns,
  ratesDataConfig,
} from "../data";

import {
  CurrencyFormatter,
  CurrencyFormat,
} from "../../../utilities/utilities";

import "./DetailsPage.css";

const { Title, Text } = Typography;
const { Panel } = Collapse;
const { Header } = Layout;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface RecordMap {
  id: number;
  title: string;
  value: string | number;
}

const DetailsPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { record } = state;
  const [propertyData, setPropertyData] = useState<{
    rates: RecordMap[];
    property: RecordMap[];
  }>();
  const [config, setConfig] = useState<ratesDataConfig>({});
  const [ratesBreakdown, setRatesBreakdown] = useState<Rates[]>([]);
  const [proposedRates, setProposedRates] = useState<Rates[]>([]);
  const [showProposedRates, setShowProposedRates] = useState<boolean>();
  const [proposedRatesColumnsState, setproposedRatesColumnsState] = useState<
    ColumnsType<Rates>
  >([]);
  const [openTables, setOpenTables] = useState<string | string[]>(["1", "2"]);

  useEffect(() => {
    const obj: ratesDataConfig = {};
    fetchConfig().then((response) => {
      response.forEach(
        (item: { attributes: { setup: string; parameter: string } }) => {
          obj[item.attributes.setup as keyof ratesDataConfig] =
            item.attributes.parameter;
        }
      );
      setConfig(obj);
    });
  }, []);

  useEffect(() => {
    const arr: Rates[] = [];
    fetchRatesBreakdown(record.ID).then((response) => {
      response.forEach((item: any) => {
        arr.push(item.attributes);
      });
      setRatesBreakdown(arr);
    });
  }, [record]);

  useEffect(() => {
    const arr: Rates[] = [];
    fetchProposedRates(record.ID).then((response) => {
      if (response.length === 0) {
        setShowProposedRates(false);
        return;
      }
      response.forEach((item: any) => {
        arr.push(item.attributes);
      });
      setShowProposedRates(true);
      setProposedRates(arr);
    });
  }, [record]);

  useEffect(() => {
    setPropertyData(map(record));
  }, [record]);

  useEffect(() => {
    const filterColumns = (columns: any) => {
      let allColumns = [...columns];
      const numColumns = 5;
      for (let i = 1; i < numColumns + 1; i++) {
        let hasDataInColumn = proposedRates.some(
          (rate: Rates) => rate[`RATE_TOTAL_COL${i}` as keyof Rates]
        );
        if (hasDataInColumn) {
          allColumns.push({
            title:
              config[`Proposed Total Col${i}` as keyof ratesDataConfig] ||
              `TOTAL ${i}`,
            dataIndex: `RATE_TOTAL_COL${i}`,
            key: `RATE_TOTAL_COL${i}`,
            align: "right",
            render: (text: number) => {
              CurrencyFormat(text, { withSymbol: true });
              return <Text>{CurrencyFormat(text, { withSymbol: true })}</Text>;
            },
          });
        }
      }
      return allColumns;
    };

    setproposedRatesColumnsState(filterColumns(proposedRatesColumns));
  }, [config, proposedRates]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const map = (raw: object) => {
    const formatter = CurrencyFormatter();

    const pmapped: RecordMap[] = Object.entries(raw)
      .filter((i: any) => PropertyValues.map((p) => p.key).includes(i[0]))
      .map((item: any, index: number) => {
        const pvalue = PropertyValues.find((n) => n.key === item[0]);
        var value = "";
        switch (pvalue?.format) {
          case "currency":
            value = CurrencyFormat(item[1], {
              formatter,
              withSymbol: true,
              noDecimal: true,
            });
            break;
          default:
            value = item[1];
        }
        const _mapped = {
          id: index,
          title: pvalue?.value || "",
          value,
        };
        return _mapped;
      });
    const rmapped: RecordMap[] = Object.entries(raw)
      .filter((i: any) => ratesValues.map((p) => p.key).includes(i[0]))
      .map((item: any, index: number) => {
        const rvalue = ratesValues.find((n) => n.key === item[0]);
        var value = "";
        switch (rvalue?.format) {
          case "currency":
            value = CurrencyFormat(item[1], {
              formatter,
              withSymbol: true,
              noDecimal: true,
            });
            break;
          default:
            value = item[1];
        }
        const _mapped = {
          id: index,
          title: rvalue?.value || "",
          value,
        };
        return _mapped;
      });
    return { rates: rmapped, property: pmapped };
  };

  const propertyColumns = [
    {
      dataIndex: "title",
      key: "title",
      width: "30%",
      render: (text: string) => <Text strong={true}>{text}</Text>,
    },
    {
      dataIndex: "value",
      key: "value",
    },
  ];

  return (
    <>
      <Header className="header">
        <Button
          onClick={() => navigate(-1)}
          icon={<LeftOutlined style={{ paddingTop: "5px" }} />}
          type="link"
          style={{ color: "black" }}
        >
          Back to Search Results
        </Button>
        <Button
          onClick={() => {
            setOpenTables(["1", "2"]);
            setTimeout(() => window.print(), 500);
          }}
          style={{ color: "black" }}
        >
          Print
        </Button>
      </Header>
      <Table
        className="propertyTable"
        rowKey="id"
        size="middle"
        bordered={true}
        pagination={false}
        showHeader={false}
        columns={propertyColumns}
        dataSource={propertyData?.property}
        title={() => <Title level={3}>Property information</Title>}
        footer={
          config["Property Footer"]
            ? () => config["Property Footer"]
            : undefined
        }
      />

      <Table
        className="pagebreak-table"
        rowKey="id"
        size="middle"
        bordered={true}
        pagination={false}
        showHeader={false}
        columns={propertyColumns}
        dataSource={propertyData?.rates}
        title={() => (
          <>
            <Title level={3}>{config["Rates Header"]}</Title>
          </>
        )}
        footer={
          config["Rates Footer"] ? () => config["Rates Footer"] : undefined
        }
      />
      <Collapse activeKey={openTables} onChange={(key) => setOpenTables(key)}>
        <Panel
          className="panelHeader"
          header={config["Rates Breakdown Header"]}
          key="1"
        >
          <Table
            rowKey="ID"
            size="small"
            bordered={true}
            locale={{
              emptyText: (
                <>
                  <Spin indicator={antIcon} />
                  <div>
                    <Text>Fetching Data</Text>
                  </div>
                </>
              ),
            }}
            pagination={false}
            columns={ratesColumns}
            dataSource={ratesBreakdown}
            summary={(pageData) => {
              let totalRates = 0;
              pageData.forEach(({ RATE_TOTAL }: any) => {
                totalRates += RATE_TOTAL;
              });
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} align="right" colSpan={5}>
                      <Text strong>
                        {CurrencyFormat(totalRates, { withSymbol: true })}
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Panel>
        {showProposedRates ? (
          <Panel
            className="panelHeader"
            header={config["Proposed Rates Breakdown Header"]}
            key="2"
          >
            <Table
              title={() => (
                <>
                  <Text>{config["Proposed Rates Breakdown Info"]}</Text>
                </>
              )}
              className="pagebreak-table"
              rowKey="ID"
              size="small"
              bordered={true}
              locale={{
                emptyText: (
                  <>
                    <Spin indicator={antIcon} />
                    <div>
                      <Text>Fetching Data</Text>
                    </div>
                  </>
                ),
              }}
              pagination={false}
              columns={proposedRatesColumnsState}
              dataSource={proposedRates}
              summary={(pageData) => {
                let totalColumn1 = 0;
                let totalColumn2 = 0;
                let totalColumn3 = 0;
                let totalColumn4 = 0;
                let totalColumn5 = 0;
                pageData.forEach(
                  ({
                    RATE_TOTAL_COL1,
                    RATE_TOTAL_COL2,
                    RATE_TOTAL_COL3,
                    RATE_TOTAL_COL4,
                    RATE_TOTAL_COL5,
                  }: any) => {
                    totalColumn1 += RATE_TOTAL_COL1;
                    totalColumn2 += RATE_TOTAL_COL2;
                    totalColumn3 += RATE_TOTAL_COL3;
                    totalColumn4 += RATE_TOTAL_COL4;
                    totalColumn5 += RATE_TOTAL_COL5;
                  }
                );
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell align="right" colSpan={3} index={0}>
                        <Text strong>
                          {CurrencyFormat(totalColumn1, { withSymbol: true })}
                        </Text>
                      </Table.Summary.Cell>
                      {totalColumn2 ? (
                        <Table.Summary.Cell align="right" index={0}>
                          <Text strong>
                            {CurrencyFormat(totalColumn2, { withSymbol: true })}
                          </Text>
                        </Table.Summary.Cell>
                      ) : null}
                      {totalColumn3 ? (
                        <Table.Summary.Cell align="right" index={0}>
                          <Text strong>
                            {CurrencyFormat(totalColumn3, { withSymbol: true })}
                          </Text>
                        </Table.Summary.Cell>
                      ) : null}
                      {totalColumn4 ? (
                        <Table.Summary.Cell align="right" index={0}>
                          <Text strong>
                            {CurrencyFormat(totalColumn4, { withSymbol: true })}
                          </Text>
                        </Table.Summary.Cell>
                      ) : null}
                      {totalColumn5 ? (
                        <Table.Summary.Cell align="right" index={0}>
                          <Text strong>
                            {CurrencyFormat(totalColumn5, { withSymbol: true })}
                          </Text>
                        </Table.Summary.Cell>
                      ) : null}
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Panel>
        ) : null}
      </Collapse>
      <Title style={{ marginLeft: "10px" }} level={3}>
        Map
      </Title>
      <div className="imageContainer">
        <Image
          alt="Top down image of property"
          className="image"
          preview={false}
          src={record.IMAGE_LINK}
        />
      </div>
      <Button
        onClick={() => navigate(-1)}
        icon={<LeftOutlined style={{ paddingTop: "5px" }} />}
        type="link"
        style={{ color: "black" }}
      >
        Back to Search Results
      </Button>
    </>
  );
};

export default DetailsPage;
