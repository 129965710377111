import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Image, Table, Typography, Button, Layout } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import "antd/dist/antd.min.css";

import { DetailsValues, formatDate } from "../data";
import "./DetailsPage.css";

const { Text } = Typography;
const { Header } = Layout;

interface RecordMap {
  id: number;
  title: string;
  value: string | number;
}

const DetailsPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { record } = state;
  const [personDetails, setPersonDetails] =
    useState<{ details: RecordMap[] }>();

  useEffect(() => {
    setPersonDetails(map(record));
  }, [record]);
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const map = (raw: object) => {
    const mapped: RecordMap[] = Object.entries(raw)
      .filter((i: any) => DetailsValues.map((p) => p.key).includes(i[0]))
      .map((item: any, index: number) => {
        const pvalue = DetailsValues.find((n) => n.key === item[0]);
        var value = "";
        switch (pvalue?.format) {
          case "date":
            value = formatDate(item[1]);
            break;
          default:
            value = item[1];
        }
        const _mapped = {
          id: index,
          title: pvalue?.value || "",
          value,
        };
        return _mapped;
      });

    return { details: mapped };
  };

  const DetailColumns = [
    {
      dataIndex: "title",
      key: "title",
      width: "15%",
      render: (text: string) => <Text strong={true}>{text}</Text>,
    },
    {
      dataIndex: "value",
      key: "value",
    },
  ];

  return (
    <>
      <Header className="header">
        <Button
          onClick={() => navigate(-1)}
          icon={<LeftOutlined style={{ paddingTop: "5px" }} />}
          type="link"
          style={{ color: "black" }}
        >
          Back to Search Results
        </Button>
        <Button onClick={() => window.print()} style={{ color: "black" }}>
          Print
        </Button>
      </Header>
      {record.ImageFile1Large ? (
        <Image
          alt="Grave Image number 1"
          width={300}
          preview={false}
          className="images"
          src={record.ImageFile1Large}
        />
      ) : null}
      {record.ImageFile2Large ? (
        <Image
          alt="Grave Image number 1"
          width={300}
          preview={false}
          className="images"
          src={record.ImageFile2Large}
        />
      ) : null}
      <Table
        className="cemeteryTable"
        rowKey="id"
        size="middle"
        bordered={true}
        pagination={false}
        showHeader={false}
        columns={DetailColumns}
        dataSource={personDetails?.details}
      />
      <Button
        onClick={() => navigate(-1)}
        icon={<LeftOutlined style={{ paddingTop: "5px" }} />}
        type="link"
        style={{ color: "black" }}
      >
        Back to Search Results
      </Button>
    </>
  );
};

export default DetailsPage;
