import axios from "axios";
//import { configFeatures, proposedRatesFeatures } from "./testData";


export const fetchConfig = () => {
  return axios
    .get(
      `https://geosite.pncc.govt.nz/arcgis/rest/services/PNCC/Website_Rates_Info/MapServer/1/query?where=1%3D1&outFields=*&f=json`
    )
    .then((response) => response.data.features) //configFeatures) //
    .catch(err => console.log(err))
};

export const searchProperty = (input: string) => {
  input = input.trim().replace(/%/g, "%25");
  if (input.match(/^[\d ]*$/)) {
    input = input.trim().replace(/ /g, "-");
  }
  return axios
    .get(
      `https://geosite.pncc.govt.nz/arcgis/rest/services/PNCC/Website_Rates_Info/MapServer/2/query?where=LOCATION+like+%27%25${input}%25%27+OR+LEGL_DESCR+like+%27%25${input}%25%27+OR+VAL_NO+like+%27%25${input}%25%27+OR+VAL_ID+like+%27${input}%27&outFields=*&f=json`
    )
    .then((response) => response.data.features)
    .catch(err => console.log(err))
};

export const fetchRatesBreakdown = (id: number) => {
  return axios
    .get(
      `https://geosite.pncc.govt.nz/arcgis/rest/services/PNCC/Website_Rates_Info/MapServer/3/query?where=ID_FK+%3D+%27${id}%27&outFields=*&f=json`
    )
    .then((response) => response.data.features)
    .catch(err => console.log(err))
};

export const fetchProposedRates = (id: number) => {
  return axios
    .get(
      `https://geosite.pncc.govt.nz/arcgis/rest/services/PNCC/Website_Rates_Info/MapServer/4/query?where=ID_FK+%3D+%27${id}%27&outFields=*&f=json`
    )
    .then((response) => response.data.features) //proposedRatesFeatures) //
    .catch(err => console.log(err))
};
