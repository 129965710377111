import axios from "axios";

export const fetchCemeteryInformation = (data: {
  firstName: string;
  lastName: string;
  date: string;
  cemetery: string;
}) => {
  data.firstName = data.firstName.replace(/'/g, '%27%27')
  data.lastName = data.lastName.replace(/'/g, '%27%27')
  return axios
    .get(
      `https://geosite.pncc.govt.nz/arcgis/rest/services/PNCC/Website_Cemetery_Info/MapServer/0/query?where=SURNAME+like+%27%25${data.lastName}%25%27+AND+FIRSTNAMES+like+%27%25${data.firstName}%25%27+AND+DATEOFDEATH+like+%27%25${data.date}%25%27+AND+CEMETERY+like+%27%25%${data.cemetery}%25%27&orderByFields=FirstNames%2CSurname&returnGeometry=false&outFields=*&f=json`
    )
    .then((response) => response.data.features);
};

