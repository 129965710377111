import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Table, Form, Button } from "antd";
import "antd/dist/antd.min.css";

import { searchProperty } from "../../../api/propertyApi";
import { searchColumns } from "../data";
import "./SearchPage.css";

const SearchPage = () => {
  const [response, setResponse] = useState<object[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  const [paginationPage, setPaginationPage] = useState(1);

  useEffect(() => {
    let data = sessionStorage.getItem("propertyResults");
    if (data) {
      setVisible(true);
      setResponse(JSON.parse(data));
    }
  }, []);

  const filterColumns = (columns:{key: string}[]) =>{
    if(window.innerWidth > 481){
      columns = columns.filter(column => column.key !== "Mobile")
    } else{
      columns = columns.filter(column => column.key === "Mobile")
    }
    return columns
  }

  const onFinish = (value: any) => {
    const arr: object[] = [];
    if (!value) {
      setResponse([]);
      sessionStorage.removeItem("propertySearch");
      sessionStorage.removeItem("propertyResults");
      return;
    } else {
      searchProperty(value.address).then((response) => {
        response.forEach((item: any) => {
          arr.push(item.attributes);
          sessionStorage.setItem("propertyResults", JSON.stringify(arr));
          sessionStorage.setItem("propertySearch", value.address);
        });
        setResponse(arr);
        setVisible(true);
        setPaginationPage(1);
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Form
        name="basic"
        initialValues={{
          remember: true,
          address: sessionStorage.getItem("propertySearch")
            ? sessionStorage.getItem("propertySearch")!
            : "",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="inline"
        className="formContainer"
      >
        <div className={"searchBox"}>
          <Form.Item name="address"
          rules={[{ whitespace:true, message:"Address Invalid" }]}>
            <Input
              placeholder="Enter Address, Assessment Number, Valuation Number, or Legal Description"
              allowClear
              size="large"
            />
          </Form.Item>
        </div>
        <Form.Item>
          <Button size="large" className="propertySubmitButton" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Form>
      {visible ? (
        <Table
          onRow={(record) => {
            return {
              onClick: () => {
                navigate("/propertyrates/details", {
                  state: { record: record },
                });
              },
            };
          }}
          rowKey="ID"
          columns={filterColumns(searchColumns)}
          dataSource={response}
          locale={{ emptyText: "No Results Found" }}
          rowClassName="rowClass"
          pagination={{
            pageSize: 5,
            current: paginationPage,
            onChange: (pageNum) => setPaginationPage(pageNum),
          }}
        />
      ) : null}
    </>
  );
};

export default SearchPage;
