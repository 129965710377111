import React from "react";
import { ColumnsType } from "antd/es/table";
import { Typography } from "antd";
import { CurrencyFormat } from "../../utilities/utilities";
const { Text } = Typography;

export const PropertyValues: {
  key: string;
  value: string;
  format?: "currency" | "number";
}[] = [
  { key: "LOCATION", value: "Location" },
  { key: "LEGL_DESCR", value: "Legal Description" },
  { key: "AREA", value: "Area (ha)" },
  {
    key: "CURR_CAPITAL_VAL",
    value: "Current Capital Value",
    format: "currency",
  },
  { key: "CURR_LAND_VAL", value: "Current Land Value", format: "currency" },
  { key: "CURR_MESSAGE", value: "Current Message" },
];

export const ratesValues: {
  key: string;
  value: string;
  format?: "currency" | "number";
}[] = [
  { key: "VAL_ID", value: "Assessment No." },
  { key: "VAL_NO", value: "Valuation Ref." },
  { key: "RATES_TOTAL", value: "Rates", format: "currency" },
  { key: "CAPITAL_VAL", value: "Capital Value", format: "currency" },
  { key: "LAND_VAL", value: "Land Value", format: "currency" },
  { key: "IMPR_VAL", value: "Improvement Value", format: "currency" },
  { key: "MESSAGE", value: "Message" },
];

export interface Rates {
  RATE_DESC: string;
  RATE_UNITS: number;
  RATE_FACTOR: number;
  RATE_TOTAL: number;
  RATE_TOTAL_COL1: number;
  RATE_TOTAL_COL2: number;
  RATE_TOTAL_COL3: number;
  RATE_TOTAL_COL4: number;
  RATE_TOTAL_COL5: number;
}

export interface ratesDataConfig {
  "Property Footer"?: string;
  "Proposed Rates Breakdown Header"?: string;
  "Proposed Rates Breakdown Info"?: string;
  "Proposed Total Col1"?: string;
  "Proposed Total Col2"?: string;
  "Proposed Total Col3"?: string;
  "Proposed Total Col4"?: string;
  "Proposed Total Col5"?: string;
  "Rates Breakdown Header"?: string;
  "Rates Footer"?: string;
  "Rates Header"?: string;
}

export const ratesColumns: ColumnsType<Rates> = [
  {
    title: "DESCRIPTION",
    dataIndex: "RATE_DESC",
    key: "RATE_DESC",
  },
  {
    title: "UNITS",
    dataIndex: "RATE_UNITS",
    key: "RATE_UNITS",
    align: "right",
  },
  {
    title: "FACTOR",
    dataIndex: "RATE_FACTOR",
    key: "RATE_FACTOR",
    align: "right",
  },
  {
    title: "TOTAL",
    dataIndex: "RATE_TOTAL",
    key: "RATE_TOTAL",
    align: "right",
    render: (text: number) => {
      CurrencyFormat(text, { withSymbol: true });
      return <Text>{CurrencyFormat(text, { withSymbol: true })}</Text>;
    },
  },
];

export const proposedRatesColumns: ColumnsType<Rates> = [
  {
    title: "DESCRIPTION",
    dataIndex: "RATE_DESC",
    key: "RATE_DESC",
  },
  {
    title: "UNITS",
    dataIndex: "RATE_UNITS",
    key: "RATE_UNITS",
    align: "right",
  },
];

export const searchColumns = [
  {
    key: "Mobile",
    render: (text: string, record: any) => (
      <>
        <div>
          <span className={"link-valuation-number"}>{record.LOCATION}</span>
        </div>
        <div>
          <span>{record.VAL_ID}</span>
        </div>
        <div>
          <span>{record.VAL_NO}</span>
        </div>
        <div>
          <span>{record.LEGL_DESCR}</span>
        </div>
      </>
    ),
  },
  {
    title: "Address",
    dataIndex: "LOCATION",
    key: "LOCATION",
    width: "33%",
    render: (text: string) => (
      <span className={"link-valuation-number"}>{text}</span>
    )
  },
  {
    title: "Assessment & Valuation Numbers",
    dataIndex: "VAL_NO",
    key: "VAL_NO",
    width: "33%",
    render: (text: string, record: any) => (
      <>
        <div>
          <span>{record.VAL_ID}</span>
        </div>
        <div>
          <span>{record.VAL_NO}</span>
        </div>
      </>
    ),
  },
  {
    title: "Legal Description",
    dataIndex: "LEGL_DESCR",
    key: "LEGL_DESCR",
    width: "33%",
  },
];

