import React from "react";
import { Route, Routes } from "react-router-dom";

import PropertySearchPage from "./tools/propertyAndRates/pages/SearchPage";
import PropertyDetailsPage from "./tools/propertyAndRates/pages/DetailsPage";
import CemeterySearchPage from "./tools/cemetery/pages/SearchPage";
import CemeteryDetailsPage from "./tools/cemetery/pages/DetailsPage";

const App = () => {
  return (
      <Routes>
        <Route path="/propertyrates/search" element={<PropertySearchPage />} />
        <Route
          path="/propertyrates/details"
          element={<PropertyDetailsPage />}
        />
        <Route path="/cemetery/search" element={<CemeterySearchPage />} />
        <Route path="/cemetery/details" element={<CemeteryDetailsPage />} />
      </Routes>
  );
};

export default App;
